import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({ color, disable = false, children, onClick }: ButtonProps) => (
  <StyledButton color={color} onClick={onClick} disabled={disable}
    style={{ cursor: disable ? "not-allowed" : "pointer", opacity: disable ? 0.5 : 1 }}
  >
    {children}
  </StyledButton>
);
