import { Col, Row } from "antd";
import { TFunction, withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import {
  Chat,
  Extra,
  FooterSection,
  Large,
  Para,
  Title
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
  name: string;
}

const Footer = ({ t }: { t: TFunction }) => {

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const SocialLink = ({ href, src, name }: SocialLinkProps) => {
    return (
      <Row align="middle">
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          key={src}
          aria-label={src}
          style={{
            marginBottom: "10px",
            marginRight: "10px"
          }}
        >
          <SvgIcon src={src} width="30px" height="30px" />
        </a>
        <Para>{name}</Para>
      </Row>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Contact")}</Title>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <Chat onClick={() => scrollTo('contact')}>{t(`Let's Chat`)}</Chat>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to="/privacy-policy">{t("Privacy Policy")}</Large>
              <Large to="/terms">{t("Terms and Conditions")}</Large>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12} >
              <Title>{t("SocialNetwork")}</Title>
              <SocialLink
                name="Instagram"
                href="https://www.instagram.com/programable.app/"
                src="instagram.svg"
              />
              <SocialLink
                name="LinkedIn"
                href="https://www.linkedin.com/in/mariano-castellano/"
                src="linkedin.svg"
              />
              <SocialLink
                name="Medium"
                href="https://medium.com/@nanocastellano"
                src="medium.svg"
              />
            </Col>
          </Row>
          <Para style={{
            paddingTop: "20px",
            textAlign: "center",
            width: "100%",
          }}>
            {t('All rights reserved')}
          </Para>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
